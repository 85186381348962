import {
  makeCreateEvent,
  makeErroredEventCreation,
  makeEventCreated,
  makeEventoFetchedAction,
  makeFetchEventoAction,
  makeFetchEventoErrorAction,
  closeSuccessPopup
} from './reducer'
import { makeHttpApiClient } from '../http/index'


export const getEventoDetail = ({ token, idRecord }) => {
  const api = makeHttpApiClient(token)
  return dispatch => {
    dispatch(makeFetchEventoAction())
    api({ path:`/events/${idRecord}` })
      .then(evento => dispatch(makeEventoFetchedAction({ evento })))
      .catch(error => dispatch(makeFetchEventoErrorAction({ error: error.error_data.error })))
  }
}

export const createEvento = ({ token, evento }) => {
  const api = makeHttpApiClient(token)
  return dispatch => {
    try {
      dispatch(makeCreateEvent({ evento }))
      api({ path:`/events`, method: 'POST', body: evento })
        .then(() => dispatch(makeEventCreated()))
        .catch(error => dispatch(makeErroredEventCreation({ error }))) 
    } catch (error) {
      dispatch(makeErroredEventCreation({ error }))
    }
  }
}

export const closePopupSuccess = dispatch => () => dispatch(closeSuccessPopup())