import React from 'react'

export const Accordion = ({ children, title }) => (
  <div className="uk-margin-large-top uk-container uk-container-small uk-position-relative">
    <ul uk-accordion="">
      <li>
        <a className="uk-accordion-title" href="#">{title}</a>
        <div className="uk-accordion-content">
          {children}
        </div>
      </li>
    </ul>
  </div>
)

export const Table = ({ elements, keysList, actionMaker }) => (
  <table className="uk-table uk-table-striped">
    <thead>
      <tr>
        {keysList.map(({ key, label }, index) => (<th key={`${key}_${index}`} >{label}</th>))}
        {actionMaker ? <th>Azioni</th> : null}    
      </tr>
    </thead>
    <tfoot>
      <tr>
        {keysList.map(({ key, label }, index) => (<th key={`${key}_${index}_foot`} >{label}</th>))} 
        {actionMaker ? <th>Azioni</th> : null}
      </tr>
    </tfoot>
    <tbody>
      {elements.map((elem, index) => (
        <tr key={`${elem.recordId}_${index}_$`}>
          {keysList.map(({ key, edit, align }, index) => (
            <td className={align ? `uk-text-${align}` : ''} key={`${elem.recordId}_${index}`} >
              {edit ? edit(elem[key] || '-') : elem[key] || '-'}
            </td>)
          )}
          {actionMaker ? <td>{actionMaker(elem)}</td> : null}
        </tr>
      ))}
    </tbody>
  </table>
)