import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getEventoDetail } from '../evento/useCases'
import { FullScreenSpinner } from '../components/map/mapContainer.jsx'
import { italianDate } from '../components/FatturaDetail.jsx';

class EventoInfoPage extends Component {

  componentDidMount() {
    const { match, fetchEventoDetails, token } = this.props
    const idRecord = match.params.idRecord
    fetchEventoDetails({ idRecord, token })
  }

  render() {
    const { isLoading, evento, error, match, history } = this.props
    return (<div className="uk-container uk-margin-top" >
      {isLoading || !evento ?
        FullScreenSpinner():
        error ?
          <div>{error.message}</div> :
          <div>
            <p className="uk-text-large uk-text-center" >ID: {match.params.idRecord}</p>
            <div className="uk-container uk-margin-bottom">
              <article className="uk-article">
                <h1 className="uk-article-title">{evento.NomeCliente}</h1>

                <p className="uk-article-meta">Data: {evento.dataCreazione}, {evento.oraNota}</p>
                <p className="uk-article-meta">Anagrafica: {evento.idAnagrafica || '-'}</p>
                <p className="uk-article-meta">Codice Agente: {evento.codiceAgente || '-' }</p>
                <p className="uk-article-meta">Agente Creatore: {evento.agenteCreatore || '-'}</p>
                
                <br/>
                <p className="uk-text-lead">{evento.testoNota}</p>
              </article>
            </div>
            <button onClick={() => history.goBack()} className="uk-button uk-button-primary uk-margin-bottom">Indietro</button>
          </div>
      }
    </div>)
  }

}

const mapStateToProps = state => ({
  token: state.auth.token,
  isLoading: state.evento.loading,
  evento: state.evento.evento,
  error: state.evento.error
})

const mapDispatchToProps = dispatch => ({
  fetchEventoDetails: ({ idRecord, token }) => dispatch(getEventoDetail({ token, idRecord }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventoInfoPage)