export const FETCH_INFO = 'FETCH_INFO'
export const INFO_FETCHED = 'INFO_FETCHED'
export const ERROR_INFO_FETCH = 'ERROR_INFO_FETCH'
export const CACHE_INFO = 'CACHE_INFO'


export const defaultState = {
  loading: false,
  info: null,
  error: null,
  cachedInfo: {}
}

export const makeFetchInfoAction = () => ({
  type: FETCH_INFO
})

export const makeInfoHasBeenFetchedAction = ({ info }) => ({
  type: INFO_FETCHED,
  data: info
})

export const makeInfoFetchErrorAction = ({ error }) => ({
  type: ERROR_INFO_FETCH,
  data: error
})

export const makeCacheInfoAction = ({ idAnagrafica, info }) => ({
  type: CACHE_INFO,
  data: {
    idAnagrafica,
    info
  }
})

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case FETCH_INFO:
      return {
        ...currentState,
        loading: true,
        error: null,
        info: null
      }
    case INFO_FETCHED:
      return {
        ...currentState,
        loading: false,
        error: null,
        info: action.data
      }
    case ERROR_INFO_FETCH:
      return {
        ...currentState,
        loading: false,
        error: null
      }
    case CACHE_INFO:
      return {
        ...currentState,
        cachedInfo: {
          ...currentState.cachedInfo,
          [action.data.idAnagrafica]: action.data.info
        }
      }
    default:
      return currentState
  }
}