export const FETCH_DEPOSITO = 'FETCH_DEPOSITO'
export const ERROR_FETCH_DEPOSITO = 'ERROR_FETCH_DEPOSITO'
export const DEPOSITO_FETCHED = 'DEPOSITO_FETCHED'

export const defaultState = {
  deposito: null,
  loading: false,
  error: null
}

export const makeFetchDepositoAction = () => ({
  type: FETCH_DEPOSITO
})

export const makeDepositoFetchedAction = ({ deposito }) => ({
  type: DEPOSITO_FETCHED,
  data: deposito
})

export const makeFetchDepositoErrorAction = ({ error }) => ({
  type: ERROR_FETCH_DEPOSITO,
  data: error
})

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case FETCH_DEPOSITO:
      return {
        ...currentState,
        loading: true
      }
    case ERROR_FETCH_DEPOSITO:
      return {
        ...currentState,
        loading: false,
        error: action.data
      }
    case DEPOSITO_FETCHED:
      return {
        ...currentState,
        deposito: action.data,
        loading: false,
        error: null
      } 
    default:
      return currentState
  }
}