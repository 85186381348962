export const DISPATCH_LOGIN = 'DISPATCH_LOGIN'
export const LOGGED_IN = 'LOGGED_IN'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const LOG_OUT = 'LOG_OUT'

export const defaultState = {
  token: null,
  loading: false,
  error: null,
  username: null,
  decoded: null
}

export function logInUser({ token, username, decoded }) {
  return {
    error: null,
    loading: false,
    token,
    username,
    decoded
  }
}

export function errorOnLogin({ error }) {
  return {
    error,
    loading: false,
    token: null
  }
}

export const dispatchLoginAction = () => ({
  type: DISPATCH_LOGIN
})

export const makeLogOutAction = () => ({
  type: LOG_OUT
})

export const loggedInAction = ({ token, username, decoded }) => ({
  type: LOGGED_IN,
  data: { token, username, decoded }
})

export const loginErrorAction = (error) => ({
  type: ERROR_LOGIN,
  data: { ...error.serialize() }
})

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case DISPATCH_LOGIN:
      return {
        ...currentState,
        loading: true,
        error: null
      }
    case LOGGED_IN:
      return logInUser(action.data)
    case ERROR_LOGIN:
      return errorOnLogin(action.data)
    case LOG_OUT:
      return {
        token: null,
        loading: false,
        username: null,
        error: null
      }
    default:
      return currentState
  }
}