import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createEvento, closePopupSuccess } from '../evento/useCases'
import { FullScreenSpinner } from '../components/map/mapContainer.jsx';

function preventDefautl(callback) {
  return token => event => {
    event.preventDefault()
    const newEventData = Array.from(event.target.elements)
      .map(input => ({ value: input.value, key: input.name }))
      .filter(input => !!input.value)
      .reduce((acc, input) => ({
        ...acc,
        [input.key]: input.value
      }), {})
    newEventData.idAnagrafica = Number(newEventData.idAnagrafica)
    callback({
      token,
      evento: newEventData
    })
  }
}

const NewEvento = ({ match, createNews, token, loading, newEvent, closePopup, history }) => {

  useEffect(() => {
    return () => {
      closePopup()
    }
  }, [])

  return (
    <div className="uk-container uk-margin-large-top">
      <h1>Creazione Nuova nota</h1>
      {loading ?
        <FullScreenSpinner />
        :
        <>
        {!newEvent ||
          <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close="" onClick={closePopup}></a>
            <p>Nota creata con successo!</p>
          </div>
        }
        <form className="uk-form-stacked" onSubmit={createNews(token)} >
          <div className="uk-margin">
            <textarea style={{ minHeight: 400 }} name="testoNota" className="uk-textarea" placeholder="Il testo che descrive la nota..."></textarea>
          </div>
          <input type="hidden" name="idAnagrafica" value={match.params.idAnagrafica}/>
          <button type="submit" className="uk-button uk-button-primary" >Crea Nota</button>
          <p onClick={() => history.goBack()} className="uk-button uk-button-default uk-margin-left" >Indietro</p>
        </form>
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.auth.token,
  codiceAgente: state.auth.decoded.agent,
  loading: state.evento.loading,
  newEvent: state.evento.newEvent
})

const mapDispatchToProps = dispatch => ({
  createNews: preventDefautl(({ token, evento }) => dispatch(createEvento({ token, evento }))),
  closePopup: closePopupSuccess(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(NewEvento)