const makeEvent = ({
  codiceAgente,
  agenteCreatore,
  testoNota,
  idAnagrafica,
  _pkNotaAgente,
  dataCreazione,
  oraNota
}) => {
  // Validation Rules
  if (!!codiceAgente && typeof codiceAgente !== "string") {
    throw new Error("Specificare chi è il creatore della nota");
  }
  if (typeof testoNota !== "string" || testoNota.length <= 0) {
    throw new Error("Impossibile creare una nota senza testo");
  }
  if (typeof idAnagrafica !== "number") {
    throw new Error(
      "Impossibile creare una nota senza riferimento ad una Anagrafica"
    );
  }

  if (typeof dataCreazione !== "string" && typeof oraNota !== "string") {
    const currentDate = new Date();
    dataCreazione = `${currentDate.getDate()}/${currentDate.getMonth() +
      1}/${currentDate.getFullYear()}`;
    oraNota = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
  }

  return Object.freeze({
    serializeFilemaker() {
      return {
        codiceAgente,
        testoNota,
        idAnagrafica,
        agenteCreatore
      };
    },

    getInfo() {
      return Object.freeze({
        testoNota,
        codiceAgente,
        agenteCreatore,
        dataCreazione,
        oraNota,
        idAnagrafica
      });
    },

    getId() {
      return _pkNotaAgente;
    }
  });
};

module.exports = { makeEvent };
