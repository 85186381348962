import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getDepositoDetail } from '../deposito/useCases'
import { FullScreenSpinner } from '../components/map/mapContainer.jsx'
import { DepositoDetail } from '../components/DepositoDetails.jsx';

class DepositoInfoPage extends Component {

  componentDidMount() {
    const { match, fetchDepositoDetail, token } = this.props
    const idDocumento = match.params.idDocumento
    fetchDepositoDetail({ idDocumento, token })
  }

  render() {
    const { isLoading, deposito, error, match, history } = this.props
    return (<div className="uk-container uk-margin-top" >
      {isLoading || !deposito ?
        FullScreenSpinner():
        error ?
          <div>{error.message}</div> :
          <div>
            <button onClick={() => history.goBack()} className="uk-button uk-button-primary uk-margin-bottom">Indietro</button>
            <p className="uk-text-large uk-text-center" >ID: {match.params.idDocumento}</p>
            <hr className="uk-divider-icon uk-margin-bottom-large"></hr>
            <DepositoDetail deposito={deposito} ></DepositoDetail>
            <button onClick={() => history.goBack()} className="uk-button uk-button-primary uk-margin-bottom">Indietro</button>
          </div>
      }
    </div>)
  }

}

const mapStateToProps = state => ({
  token: state.auth.token,
  isLoading: state.deposito.loading,
  deposito: state.deposito.deposito,
  error: state.deposito.error
})

const mapDispatchToProps = dispatch => ({
  fetchDepositoDetail: ({ idDocumento, token }) => dispatch(getDepositoDetail({ token, idDocumento }))
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositoInfoPage)