import React from "react";
import { connect } from "react-redux";
import { select } from "../../marker/useCases";

const Marker = ({ marker, clickMarker }) => (
  <div height={50} width={50} style={{ cursor: "pointer" }}>
    <img
      className={"uk-animation-scale-up"}
      onClick={clickMarker(marker)}
      src={`/icons/${marker.codificaImmagine_2021}`}
      height={50}
      width={50}
    />
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  clickMarker: (marker) => () => select(dispatch)({ marker }),
});

export default connect(null, mapDispatchToProps)(Marker);
