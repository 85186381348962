import LoginPage from './login.jsx'
import DashboardPage from './dashboard.jsx'
import DetailPage from './detail.jsx'
import FatturaDetailPage from './fattura.jsx'
import DepositoDetailPage from './deposito.jsx'
import EventoDetailPage from './evento.jsx'
import NewEvento from './newEvento.jsx'

export default {
  LoginPage,
  DashboardPage,
  DetailPage,
  FatturaDetailPage,
  DepositoDetailPage,
  EventoDetailPage,
  NewEvento
}