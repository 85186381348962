import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { isAuthenticated } from '../auth/useCases'
import { FullScreenSpinner } from './map/mapContainer.jsx'

const protectedRoute = ({ component: Component, authState, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (authState.loading) {
        return FullScreenSpinner()
      }
      if (isAuthenticated(authState)) {
        return <Component {...props} />
      } else {
        return <Redirect to='/login' />
      }
    }}
  />
)

const mapStateToProps = state => {
  return {
    authState: state.auth
  }
}

export default connect(
  mapStateToProps
)(protectedRoute)