import React from 'react'
import { Table } from './Table.jsx';

const euros = (price) => price === '-' ? price : `${price}€`

const isNumerable = a => typeof a !== 'number' && /^(((\+|\-)[0-9]*\.[0-9]*|[0-9]*\.[0-9]*)|(\+[0-9]*|[0-9]*))$/gm.test(a)

const addPerc = discount => discount === '-' ? discount : `${discount}%`
const toNumber = discount => isNumerable(discount) ? Number(discount) : discount

const subs = ({ find, repl }) => str => String(str).replace(find, repl)

export const sanitizePrice = (priceStr) => {
  if (isNumerable(priceStr)) {
    const match = /\+|\-/gm.exec(priceStr)  
    const plusOrMinus = match ? match[0] : ''
    return `${plusOrMinus} ${Number(priceStr.replace(/\+|\-/gm, '')).toFixed(2)}`
  } else {
    if (typeof priceStr === 'number') {
      return priceStr.toFixed(2)
    }
    return priceStr
  }
}

export const pipeFunc = (...funcs) => val => funcs.reduce((prev, func) => func(prev), val)

export const parseMoney = pipeFunc(sanitizePrice, subs({ find: '.', repl: ',' }), euros)
export const perc = pipeFunc(toNumber, addPerc)

const dateWithZero = month => {
  if(month < 10) {
    return `0${month}`
  }
  return `${month}`
}
const createDate = date => new Date(date)
const formatDate = date => `${dateWithZero(date.getDate())}/${dateWithZero(date.getMonth() + 1)}/${date.getFullYear()}` 
export const italianDate = pipeFunc(createDate, formatDate)

const Grid = ({ fieldData }) => (
  <div className="uk-child-width-expand@s" uk-grid=''>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Data Doc:</p>
        <p>{italianDate(fieldData.dataDocumento)}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">N Fattura:</p>
        <p>{fieldData.DVT_NUMERO_FATTURA}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Tot Merce:</p>
        <p>{fieldData['Totale merce'] || '-'} €</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Tot RIBA:</p>
        <p>{fieldData['Totale Spese RIBA'] || '-'} €</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Tot Doc:</p>
        <p>{fieldData.totaleDocumento || '-'} €</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Tot IVA:</p>
        <p>{fieldData['Totale IVA'] || '-'} €</p>
      </div>
    </div>
  </div>
)

export const FatturaDetail = ({ fattura: { fieldData, righe_fattura, movimenti, scadenze } }) => (
  <div className="uk-margin-top">
    <Grid fieldData={fieldData} />
    <Table
      elements={righe_fattura}
      keysList={[
        { key: 'DVR_CODICE_ART', label: 'Codice Art' },
        { key: 'DVR_DESCRIZIONE1', label: 'Descrizione' }, 
        { key: 'DVR_DESCRIZIONE2', label: 'Descrizione' },
        { key: 'DVR_PREZZO', label: 'Prezzo', edit: parseMoney, align: 'right' },
        { key: 'DVR_SCONTO_CLI', label: 'Sconto Cliente', edit: perc, align: 'right' },
        { key: 'DVR_PREZZO_NETTO', label: 'Prezzo Netto', edit: parseMoney, align: 'right' },
        { key: 'DVR_IMPORTO', label: 'Importo', edit: parseMoney, align: 'right' },
        { key: 'DVR_IMPORTO_IVA', label: 'Importo Iva', edit: parseMoney, align: 'right' },
        { key: 'DVR_TIPO_VENDITA', label: 'Tipo' },
        { key: 'DVR_QUANTITA', label: 'Quantità' }
      ]}
    />
    <hr className="uk-divider-icon"></hr>
    <p className="uk-text-large uk-margin-large-top">Movimenti</p>
    <Table
      elements={movimenti} 
      keysList={[
        { key: 'Data Registrazione', label: 'Data Registrazione',  edit: italianDate },
        { key: 'PAR_IMPORTO', label: 'Importo Par', edit: parseMoney }, 
        { key: 'PAR_DESCRIZIONE1', label: 'Descrizione' },
        { key: 'PAR_DESCRIZIONE2', label: 'Descrizione' }
      ]}
    />
    <hr className="uk-divider-icon"></hr>
    <p className="uk-text-large uk-margin-large-top">Scadenze</p>
    <Table
      elements={scadenze}
      keysList={[
        { key: 'Data Scadenza', label: 'Data Scadenza', edit: italianDate },
        { key: 'PAS_DARE', label: 'Pas Dare', edit: parseMoney }
      ]}
    />
  </div>
)