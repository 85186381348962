import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import markerReducer from "./marker/reducer";
import infoReducer from "./info/reducer";
import fatturaReducer from "./fattura/reducer";
import depositoReducer from "./deposito/reducer";
import eventoReducer from "./evento/reducer";
import searchReducer from "./search/reducer";

export const STORAGE_KEY = "auth";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return {
      auth: JSON.parse(atob(serializedState)),
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state.auth);
    localStorage.setItem(STORAGE_KEY, btoa(serializedState));
  } catch (err) {
    // ignore write errors
    console.log(err);
  }
};

export default combineReducers({
  auth: authReducer,
  map: markerReducer,
  info: infoReducer,
  fattura: fatturaReducer,
  deposito: depositoReducer,
  evento: eventoReducer,
  search: searchReducer,
});
