export const FETCH_FATTURA = 'FETCH_FATTURA'
export const ERROR_FETCH_FATTURA = 'ERROR_FETCH_FATTURA'
export const FATTURA_FETCHED = 'FATTURA_FETCHED'

export const defaultState = {
  fattura: null,
  loading: false,
  error: null
}

export const makeFetchFatturaAction = () => ({
  type: FETCH_FATTURA
})

export const makeFatturaFetchedAction = ({ fattura }) => ({
  type: FATTURA_FETCHED,
  data: fattura
})

export const makeFetchFatturaErrorAction = ({ error }) => ({
  type: ERROR_FETCH_FATTURA,
  data: error
})

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case FETCH_FATTURA:
      return {
        ...currentState,
        loading: true
      }
    case ERROR_FETCH_FATTURA:
      return {
        ...currentState,
        loading: false,
        error: action.data
      }
    case FATTURA_FETCHED:
      return {
        ...currentState,
        fattura: action.data,
        loading: false,
        error: null
      } 
    default:
      return currentState
  }
}