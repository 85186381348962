import {
  makeFetchMarkersAction,
  makeMarkersFetchedAction,
  makeFetchMarkersErrorAction,
  makeSelectMarkerAction,
  makeDeselectMarkerAction,
  makeUpdateCenterAction,
  makeUpdateZoomAction
} from './reducer'
import { makeHttpApiClient } from '../http/index'

export function getMarkers({ token }) {
  const api = makeHttpApiClient(token)
  return dispatch => {
    dispatch(makeFetchMarkersAction())
    api({ path: '/markers' })
      .then(markers => dispatch(makeMarkersFetchedAction({ markers })))
      .catch(error => dispatch(makeFetchMarkersErrorAction({ error: error.error_data.error })))
  }
}

export const select = (dispatch) => ({ marker }) => dispatch(makeSelectMarkerAction({ marker }))
export const deselect = (dispatch) => ({ marker }) => dispatch(makeDeselectMarkerAction({ marker }))

export const makeSelectorMarker = ({ idAnagrafica }) => ({ idAnagrafica })
export {
  makeUpdateCenterAction,
  makeUpdateZoomAction
}