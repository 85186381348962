const basepath = '/api'
const commonHeaders = {
  'Content-Type': 'application/json'
}

class HttpRequestError extends Error {
  constructor(error_data) {
    super()
    this.error_data = error_data
    this.serialize = this.serialize.bind(this)
  }
  serialize() {
    return this.error_data
  }
}

export const makeHttpApiClient = (token) => {
  const headers = token ?
    {
      ...commonHeaders,
      'Authorization': `Bearer ${token}`
    }
    :
    commonHeaders
  return ({ path, method = 'GET', body = null }) => fetch(basepath + path, {
    method,
    headers,
    body: body ? JSON.stringify(body) : null
  })
    .then(res => res.json()
        .then(body => ({ body, response: res }))
    )
    .then(({ response, body }) => {
      if (response.ok) {
        return body
      } else {
        throw new HttpRequestError(body)
      }
    })
}