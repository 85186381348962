import {
  makeFetchDepositoAction,
  makeDepositoFetchedAction,
  makeFetchDepositoErrorAction
} from './reducer'
import { makeHttpApiClient } from '../http/index'

export const getDepositoDetail = ({ token, idDocumento }) => {
  const api = makeHttpApiClient(token)
  return dispatch => {
    dispatch(makeFetchDepositoAction())
    api({ path:`/deposito/${idDocumento}` })
      .then(deposito => dispatch(makeDepositoFetchedAction({ deposito })))
      .catch(error => dispatch(makeFetchDepositoErrorAction({ error: error.error_data.error })))
  }
}