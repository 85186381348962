import React, { Fragment } from "react";
import { connect } from "react-redux";
import { deselect } from "../marker/useCases";
import { Link } from "react-router-dom";
import { Accordion } from "./Table.jsx";

const sidebarStyle = {
  position: "absolute",
  height: "calc(100% - 80px)",
  top: 80,
  left: 0,
  width: 600,
  background: "white",
  zIndex: 2,
  overflow: "scroll",
};

const ContextualSidebar = ({ selectedMarker, deselectMarker }) =>
  selectedMarker ? (
    <div
      style={sidebarStyle}
      className={"uk-animation-slide-left uk-card uk-card-body uk-container"}
    >
      <dl className="uk-description-list">
        <dt>ID Cliente:</dt>
        <dd>{selectedMarker.idAnagrafica}</dd>
        <dt>ID Agente:</dt>
        <dd>{selectedMarker.codAgenteAttuale}</dd>
        <dt>Ragione Sociale:</dt>
        <dd>{selectedMarker.ragioneSocialeWeb}</dd>
        <dt>Indirizzo:</dt>
        <dd>
          {selectedMarker.Indirizzo} {selectedMarker.CAP}{" "}
          {selectedMarker.SiglaProvincia} {selectedMarker.Comune}
        </dd>
        <dt>Telefono:</dt>
        <dd>
          <a
            className={"uk-link-muted"}
            href={`tel:${selectedMarker.telAnagrafica}`}
          >
            {selectedMarker.telAnagrafica}
          </a>
        </dd>
        <dt>Posizione:</dt>
        <dd>
          <a
            className={"uk-link-muted"}
            href={`https://www.google.com/maps/search/?api=1&query=${selectedMarker.gps_lat},${selectedMarker.gps_long}`}
          >
            Apri in maps
          </a>
        </dd>
      </dl>
      <Accordion title="Contatti">
        {selectedMarker.contatti.map((contatto, index) => (
          <Fragment key={index}>
            <dl className="uk-description-list">
              <dt>Nome e Cognome</dt>
              <dd>{contatto["contattiWeb::nome"]}</dd>
              <dt>Ruolo:</dt>
              <dd>{contatto["contattiWeb::ruolo"]}</dd>
              <dt>Telefono:</dt>
              <dd>
                <a
                  className={"uk-link-muted"}
                  href={`tel:${contatto["contattiWeb::telefono"]}`}
                >
                  {contatto["contattiWeb::telefono"]}
                </a>
              </dd>
            </dl>
            <hr className="uk-divider-small" />
          </Fragment>
        ))}
      </Accordion>
      <div
        className={"uk-container uk-margin-large-top uk-margin-large-bottom"}
      >
        {selectedMarker.clienteAgente === 1 ? (
          <Link to={`/info/${selectedMarker.idAnagrafica}`}>
            <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-small">
              Dettagli
            </button>
          </Link>
        ) : null}
        <button
          onClick={deselectMarker(selectedMarker)}
          className="uk-button uk-button-danger uk-width-1-1"
        >
          Chiudi
        </button>
      </div>
    </div>
  ) : null;

const mapStateToProps = (state) => ({
  selectedMarker: state.map.selected,
});

const mapDispatchToProps = (dispatch) => ({
  deselectMarker: (marker) => () => deselect(dispatch)({ marker }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextualSidebar);
