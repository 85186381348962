export const FETCH_SEARCH = "FETCH_SEARCH";
export const SEARCH_FETCHED = "SEARCH_FETCHED";
export const FETCH_SEARCH_ERROR = "FETCH_SEARCH_ERROR";
export const OPEN_SEARCH_RESULT = "OPEN_SEARCH_RESULT";
export const CLOSE_SEARCH_RESULT = "CLOSE_SEARCH_RESULT";

export const defaultState = {
  loading: false,
  searchResults: [],
  error: null,
  open: false,
};

export function makeSearchResultOpen() {
  return {
    type: OPEN_SEARCH_RESULT,
  };
}

export function makeSearchResultClose() {
  return {
    type: CLOSE_SEARCH_RESULT,
  };
}

export function makeFetchSearchAction({ searchText }) {
  return {
    type: FETCH_SEARCH,
    data: searchText,
  };
}

export function makeFetchSearchErrorAction({ error }) {
  return {
    type: FETCH_SEARCH_ERROR,
    data: error,
  };
}

export function makeFetchedSearchAction({ markers }) {
  return {
    type: SEARCH_FETCHED,
    data: markers,
  };
}

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case FETCH_SEARCH:
      return {
        ...currentState,
        loading: true,
        error: null,
      };
    case FETCH_SEARCH_ERROR:
      return {
        ...currentState,
        loading: false,
        error: action.data,
      };
    case SEARCH_FETCHED:
      return {
        ...currentState,
        loading: false,
        searchResults: action.data,
      };
    case OPEN_SEARCH_RESULT:
      return {
        ...currentState,
        open: true,
      };
    case CLOSE_SEARCH_RESULT:
      return {
        ...currentState,
        open: false,
      };
    default:
      return currentState;
  }
};
