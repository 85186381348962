import { dispatchLoginAction, loggedInAction, loginErrorAction, makeLogOutAction } from './reducer'
import { makeHttpApiClient } from '../http/index'
import jwt_decode from 'jwt-decode'
import { STORAGE_KEY } from '../reducer'

export function isAuthenticated({ error, token, loading }) {
  if (error || loading || !token) {
    return false
  }
  const { exp } = jwt_decode(token)
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true
}

export function logIn({ username, password }) {
  const api = makeHttpApiClient()
  return dispatch => {
    dispatch(dispatchLoginAction())
    return api({ path: '/login', method: 'POST', body: { username, password } })
      .then(data => dispatch(loggedInAction({ ...data, decoded: jwt_decode(data.token) })))
      .then(() => window.location.href = '/')
      .catch(err => dispatch(loginErrorAction(err)))
  }
}

export function logOut() {
  localStorage.removeItem(STORAGE_KEY)
  return makeLogOutAction()
}