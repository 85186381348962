import React, { Component } from "react";
import Map from "google-map-react";
import {
  getMarkers,
  select,
  deselect,
  makeUpdateZoomAction,
  makeUpdateCenterAction,
} from "../../marker/useCases";
import { connect } from "react-redux";
import Marker from "./marker.jsx";

const renderMarkers = (markers) =>
  markers.map((marker) => (
    <Marker
      marker={marker}
      key={marker.idAnagrafica}
      lat={marker.gps_lat}
      lng={marker.gps_long}
    />
  ));

export const FullScreenSpinner = () => (
  <div
    className="uk-inline"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.70)",
    }}
  >
    <div className="uk-position-center" uk-spinner="ratio: 3"></div>
  </div>
);

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.googleMaps = React.createRef();
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.map = {
      zoom: props.map.zoom,
      center: props.map.center,
    };
  }

  componentDidMount() {
    if (this.props.map.markers.length <= 0) {
      this.props.loadMarkers(this.props.token);
    }
    this.map = {
      zoom: this.props.map.zoom,
      center: this.props.map.center,
    };
  }

  onZoomChange() {
    const zoom = this.googleMaps.current.geoService_.transform_.zoom;
    this.props.updateZoom(zoom);
  }

  onDrag() {
    const center = this.googleMaps.current.geoService_.transform_.center;
    this.props.updateCenter(center);
  }

  render() {
    const { style, map } = this.props;
    return (
      <div>
        <Map
          bootstrapURLKeys={{ key: __GOOGLE_MAPS_API_KEY__ }}
          style={style}
          center={this.props.map.center}
          zoom={this.props.map.zoom}
          onZoomAnimationEnd={this.onZoomChange}
          onDrag={this.onDrag}
          ref={this.googleMaps}
        >
          {renderMarkers(map.markers)}
        </Map>
        {map.loading ? FullScreenSpinner() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  selectMarker: select(dispatch),
  deselectMarker: deselect(dispatch),
  loadMarkers: (token) => dispatch(getMarkers({ token })),
  updateZoom: (zoom) => dispatch(makeUpdateZoomAction(zoom)),
  updateCenter: (center) => dispatch(makeUpdateCenterAction(center)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
