import React from 'react'
import { Table } from './Table.jsx';
import { parseMoney, perc, italianDate } from './FatturaDetail.jsx'

const Grid = ({ fieldData }) => (
  <div className="uk-child-width-expand@s" uk-grid=''>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Data Conto deposito:</p>
        <p>{italianDate(fieldData.dataContoDeposito)}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">N deposito:</p>
        <p>{fieldData.nrContoDepositoVisualizzato}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Stato Deposito:</p>
        <p>{fieldData['statoContoDeposito'] || '-'}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Creatore:</p>
        <p>{fieldData.codiceAgente || '-'}</p>
      </div>
    </div>
    <div>
      <div className="uk-card uk-text-center">
        <p className="uk-text-bold">Tot Doc:</p>
        <p>{parseMoney(fieldData['Totale Documento']) || '-'}</p>
      </div>
    </div>
    {fieldData.linkPdf ? 
      <div>
        <div className="uk-card uk-text-center">
          <p className="uk-text-bold">File:</p>
          <a className="uk-button uk-button-primary" href={fieldData.linkPdf} target="_blank" >Apri il file</a>
        </div>
      </div> :
      null
    }
  </div>
)

export const DepositoDetail = ({ deposito: { fieldData, righe_deposito } }) => (
  <div className="uk-margin-top">
    <Grid fieldData={fieldData} />
    <Table
      elements={righe_deposito}
      keysList={[
        { key: 'ISBN', label: 'ISBN' },
        { key: 'Cod. Art.', label: 'Codice Articolo' },
        { key: 'Titolo', label: 'Titolo' },
        { key: 'Prezzo Euro', label: 'Prezzo', edit: parseMoney, align: 'right' },
        { key: 'Sconto articolo', label: 'Sconto Articolo', edit: perc, align: 'right' },
        { key: 'Quantità', label: 'Quantità' }
      ]}
    />
  </div>
)