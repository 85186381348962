import {
  makeFetchFatturaAction,
  makeFatturaFetchedAction,
  makeFetchFatturaErrorAction
} from './reducer'
import { makeHttpApiClient } from '../http/index'

export const getFatturaDetail = ({ token, dtv }) => {
  const api = makeHttpApiClient(token)
  return dispatch => {
    dispatch(makeFetchFatturaAction())
    api({ path:`/fattura/${dtv}` })
      .then(fattura => dispatch(makeFatturaFetchedAction({ fattura })))
      .catch(error => dispatch(makeFetchFatturaErrorAction({ error: error.error_data.error })))
  }
}