import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchInfoBy } from "../info/useCases";
import { getMarkers } from "../marker/useCases";
import { FullScreenSpinner } from "../components/map/mapContainer.jsx";
import { Table, Accordion } from "../components/Table.jsx";
import { Link } from "react-router-dom";
import { parseMoney, italianDate } from "../components/FatturaDetail.jsx";

class DetailPage extends Component {
  componentDidMount() {
    const {
      loadInfo,
      infoAnagrafica,
      token,
      match,
      selectedMarker,
      loadMarker,
    } = this.props;
    const idAnagrafica = match.params.id;
    loadInfo({
      idAnagrafica,
      token,
      cachedInfo: infoAnagrafica.cachedInfo,
    });
    if (
      typeof selectedMarker === "function" ||
      typeof selectedMarker === "undefined"
    ) {
      loadMarker({ token });
    }
  }

  render() {
    const { infoAnagrafica, selectedMarker, match, markerLoading } = this.props;
    const idAnagrafica = match.params.id;
    let marker;
    if (typeof selectedMarker === "function") {
      marker = selectedMarker({ idAnagrafica })[0];
    }
    if (
      typeof selectedMarker !== "undefined" &&
      typeof selectedMarker !== "function"
    ) {
      marker = selectedMarker;
    }
    return infoAnagrafica.loading || !infoAnagrafica.info ? (
      FullScreenSpinner()
    ) : (
      <div>
        <div className="uk-align-center uk-margin-large">
          <div className="uk-container uk-margin-top">
            <p className="uk-text-large">ID: {idAnagrafica}</p>
            <p className="uk-text-large">
              Ragione sociale:{" "}
              {markerLoading || !marker
                ? "Caricamento..."
                : marker.ragioneSocialeWeb}
            </p>
          </div>
          {infoAnagrafica.info.fatture.length > 0 ? (
            <Accordion title="Fatture">
              <Table
                elements={infoAnagrafica.info.fatture}
                keysList={[
                  {
                    key: "DVTStoriaFatture::DVT_NUMERO_FATTURA",
                    label: "Numero Fattura",
                  },
                  {
                    key: "DVTStoriaFatture::dataDocumento",
                    label: "Data Documento",
                    edit: italianDate,
                  },
                  {
                    key: "DVTStoriaFatture::totaleDocumento",
                    label: "Totale Fattura",
                    edit: parseMoney,
                    align: "right",
                  },
                  {
                    key: "DVTStoriaFatture::statoPagamento",
                    label: "Stato Pag",
                  },
                  {
                    key: "DVTStoriaFatture::Pagamento",
                    label: "Tipo Pag",
                  },
                ]}
                actionMaker={(element) => (
                  <Link
                    to={`/fattura/${element["DVTStoriaFatture::DVT_PROGRESSIVO_FAT"]}/${element.recordId}`}
                  >
                    <button className="uk-button uk-button-primary">
                      Info
                    </button>
                  </Link>
                )}
              />
            </Accordion>
          ) : null}

          {infoAnagrafica.info.depositi.length > 0 ? (
            <Accordion title="Depositi">
              <Table
                elements={infoAnagrafica.info.depositi}
                keysList={[
                  {
                    key: "DocumentiCD::nrContoDepositoVisualizzato",
                    label: "Numero Deposito",
                  },
                  {
                    key: "DocumentiCD::dataContoDeposito",
                    label: "Data",
                    edit: italianDate,
                  },
                  {
                    key: "DocumentiCD::statoContoDeposito",
                    label: "Stato Deposito",
                  },
                ]}
                actionMaker={(element) => (
                  <Link
                    to={`/deposito/${element["DocumentiCD::IdDocumentoAssoluto"]}`}
                  >
                    <button className="uk-button uk-button-primary">
                      Info
                    </button>
                  </Link>
                )}
              />
            </Accordion>
          ) : null}

          {infoAnagrafica.info.note.length > 0 ? (
            <Accordion title="Note">
              <Table
                elements={infoAnagrafica.info.note}
                keysList={[
                  { key: "noteAgente::agenteCreatore", label: "Creatore" },
                  { key: "noteAgente::dataCreazione", label: "Data Creazione" },
                  {
                    key: "noteAgente::oraNota",
                    label: "Orario Creazione Nota",
                  },
                ]}
                actionMaker={(element) => (
                  <Link to={`/nota/${element["noteAgente::_pkNotaAgente"]}`}>
                    <button className="uk-button uk-button-primary">
                      Info
                    </button>
                  </Link>
                )}
              />
            </Accordion>
          ) : null}

          <div className={"uk-container uk-margin-large-top"}>
            <Link to={`/new/note/${idAnagrafica}`}>
              <button className="uk-button uk-button-secondary uk-width-11 uk-margin-small">
                Crea una nuova Nota
              </button>
            </Link>
            <Link to={`/`}>
              <button className="uk-button uk-button-primary uk-width-11 uk-margin-small">
                Indietro
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  infoAnagrafica: state.info,
  selectedMarker:
    state.map.selected ||
    (({ idAnagrafica }) =>
      state.map.markers.filter(
        (marker) => marker.idAnagrafica === Number(idAnagrafica)
      )),
  markerLoading: state.map.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadInfo: ({ idAnagrafica, token, cachedInfo }) =>
    dispatch(fetchInfoBy({ cachedInfo, token })({ idAnagrafica })),
  loadMarker: ({ token }) => dispatch(getMarkers({ token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
