import React, { Fragment } from "react";
import { connect } from "react-redux";
import { deselect, select } from "../marker/useCases";

const sidebarStyle = {
  position: "absolute",
  height: "calc(100% - 80px)",
  top: 80,
  left: 0,
  width: 600,
  background: "white",
  zIndex: 2,
  overflow: "scroll",
};

const SearchResultSideBar = ({
  selectMarker,
  isOpen,
  searchLoading,
  searchResults,
}) =>
  isOpen ? (
    <div
      style={sidebarStyle}
      className={"uk-animation-slide-left uk-card uk-card-body uk-container"}
    >
      {searchLoading ? <p>Loading...</p> : null}

      <dl class="uk-description-list uk-description-list-divider">
        {searchResults.map((marker, index) => (
          <Fragment key={index}>
            <dt>
              <a onClick={selectMarker(marker)}>{marker.ragioneSocialeWeb}</a>
            </dt>
            <dd>
              {marker.Indirizzo} {marker.Comune} {marker.CAP}
            </dd>
          </Fragment>
        ))}
      </dl>
    </div>
  ) : null;

const mapStateToProps = (state) => ({
  searchResults: state.search.searchResults,
  searchLoading: state.search.loading,
  isOpen: state.search.open,
});

const mapDispatchToProps = (dispatch) => ({
  selectMarker: (marker) => () => select(dispatch)({ marker }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultSideBar);
