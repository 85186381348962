export const FETCH_MARKERS = "FETCH_MARKERS";
export const MARKERS_FETCHED = "MARKERS_FETCHED";
export const FETCH_MARKERS_ERROR = "FETCH_MARKERS_ERROR";
export const SELECT_MARKER = "SELECT_MARKER";
export const DESELECT_MARKER = "DESELECT_MARKER";
export const MOVE_CENTER = "MOVE_CENTER";
export const CHANGE_ZOOM = "CHANGE_ZOOM";

const ITALY = {
  lat: 44.023317,
  lng: 11.447516,
};

const SELECT_ZOOM_LEVEL = 18;

export const defaultState = {
  markers: [],
  loading: false,
  error: null,
  selected: null,
  zoom: 7,
  center: ITALY,
};

function deselectMarker(state, marker) {
  if (state.selected.idAnagrafica === marker.idAnagrafica) {
    return {
      ...state,
      selected: null,
    };
  }
  return state;
}

export function makeUpdateZoomAction(newZoom) {
  return {
    type: CHANGE_ZOOM,
    data: newZoom,
  };
}

export function makeUpdateCenterAction({ lat, lng }) {
  return {
    type: MOVE_CENTER,
    data: {
      lat,
      lng,
    },
  };
}

export function makeFetchMarkersAction() {
  return {
    type: FETCH_MARKERS,
  };
}

export function makeMarkersFetchedAction({ markers }) {
  return {
    type: MARKERS_FETCHED,
    data: markers,
  };
}

export function makeFetchMarkersErrorAction({ error }) {
  return {
    type: FETCH_MARKERS_ERROR,
    data: error,
  };
}

export function makeSelectMarkerAction({ marker }) {
  return {
    type: SELECT_MARKER,
    data: marker,
  };
}

export function makeDeselectMarkerAction({ marker }) {
  return {
    type: DESELECT_MARKER,
    data: marker,
  };
}

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case CHANGE_ZOOM:
      return {
        ...currentState,
        zoom: action.data,
      };
    case MOVE_CENTER:
      return {
        ...currentState,
        center: action.data,
      };
    case SELECT_MARKER:
      return {
        ...currentState,
        selected: action.data,
        zoom: SELECT_ZOOM_LEVEL,
        center: {
          lat: Number(action.data.gps_lat),
          lng: Number(action.data.gps_long),
        },
      };
    case DESELECT_MARKER:
      return deselectMarker(currentState, action.data);
    case FETCH_MARKERS:
      return {
        ...currentState,
        loading: true,
      };
    case MARKERS_FETCHED:
      return {
        ...currentState,
        loading: false,
        markers: action.data,
        error: null,
      };
    case FETCH_MARKERS_ERROR:
      return {
        ...currentState,
        error: action.data,
        loading: false,
        markers: currentState.markers,
      };
    default:
      return currentState;
  }
};
