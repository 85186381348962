import React from 'react'
import { connect } from 'react-redux'
import { logIn } from '../auth/useCases'
import { FullScreenSpinner } from '../components/map/mapContainer.jsx'

const submitForm = (action) => (event) => {
  event.preventDefault()
  const username = event.target.username.value
  const password = event.target.password.value
  action({ username, password })
}

const Isloading = check => isLoadingCmp => isNotLoadingCmp => check ? isLoadingCmp : isNotLoadingCmp

const LoginPage = ({ logIn, auth }) => (
  <div className="uk-container uk-position-center">
    {Isloading(auth.loading)
      (FullScreenSpinner())
      (<form onSubmit={submitForm(logIn)}>
          <fieldset className="uk-fieldset uk-margin">
            <input className="uk-input uk-margin" name="username" type="text"></input>
            <input className="uk-input" name="password" type="password"></input>
          </fieldset>
        <button className="uk-button uk-button-default" type="submit" >Login</button>
      </form>
    )}
    {auth.error ?
      (<div className="uk-alert-danger">
        <p>{auth.error.message}</p>
      </div>) :
      null
    }
  </div>
)

const mapDispatchToProps = dispatch => {
  return {
    logIn: ({ username, password }) => dispatch(logIn({ username, password }))
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const LoginPageConnected = connect(mapStateToProps, mapDispatchToProps)(LoginPage)

export default LoginPageConnected