import React from "react";
import { connect } from "react-redux";
import MapContainer from "../components/map/mapContainer.jsx";
import ContextualSideBar from "../components/contextualSIdeBar.jsx";
import SearchResultSideBar from "../components/searchResultSideBar.jsx";

const DashboardPage = (props) => (
  <div>
    <SearchResultSideBar />
    <ContextualSideBar />
    <MapContainer
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  </div>
);

const DashboardPageConnected = connect()(DashboardPage);

export default DashboardPageConnected;
