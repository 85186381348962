import React from 'react'
import { connect } from 'react-redux'

const collapseError = state => state.auth.error || state.map.error || state.info.error || state.fattura.error || state.deposito.error || state.evento.error

const Alert = ({ isError, message }) => (
  <div style={{ position: 'absolute', bottom: 0, zIndex: 5, width: '100%', textAlign: 'center' }}>
  {isError ?
    (<div className="uk-alert-danger" uk-alert="">
      <a className="uk-alert-close" uk-close=""></a>
      <p>{message}</p>
    </div>) :
    null
  }
  </div>
)

const mapStateToProps = state => ({
  isError: !!collapseError(state),
  message: !!collapseError(state) ? collapseError(state).message || 'Errore sconosciuto' : null
})

export default connect(mapStateToProps)(Alert)