import {
  makeFetchSearchAction,
  makeFetchSearchErrorAction,
  makeFetchedSearchAction,
  makeSearchResultOpen,
  makeSearchResultClose,
} from "./reducer";
import { makeHttpApiClient } from "../http/index";

export function searchMarkers({ token, searchText }) {
  const api = makeHttpApiClient(token);
  return (dispatch) => {
    dispatch(makeFetchSearchAction({ searchText }));
    api({ path: `/markers?searchText=${searchText}` })
      .then((markers) => dispatch(makeFetchedSearchAction({ markers })))
      .catch((error) =>
        dispatch(makeFetchSearchErrorAction({ error: error.error_data.error }))
      );
  };
}

export function openSearch(dispatch) {
  return dispatch(makeSearchResultOpen());
}

export function closeSearch(dispatch) {
  return dispatch(makeSearchResultClose());
}
