import React, { useState } from "react";
import { connect } from "react-redux";
import { isAuthenticated, logOut } from "../auth/useCases";
import { Link } from "react-router-dom";
import { getMarkers, deselect } from "../marker/useCases";
import { searchMarkers, openSearch, closeSearch } from "../search/useCases";

const TopBar = ({
  username,
  isAuth,
  logout,
  token,
  reloadMarkers,
  searchMarkers,
  openSearch,
  searchIsOpen,
  closeSearch,
  deselectMarker,
  selectedMarker,
}) => {
  const [searchText, setSearchText] = useState("");
  const executeSearch = searchMarkers({ token });
  return (
    <nav className="uk-navbar-container alawaysTop" uk-navbar="">
      <div className="uk-navbar-left">
        <ul className="uk-navbar-nav uk-margin-large-left">
          {isAuth ? (
            <li>
              <Link to="/">
                <p className="uk-active" uk-icon="icon: home; ratio: 2"></p>
              </Link>
            </li>
          ) : null}
          {isAuth ? (
            <li>
              <a
                onClick={reloadMarkers({ token })}
                className="uk-active"
                uk-icon="icon: refresh; ratio: 2"
              ></a>
            </li>
          ) : null}
          {isAuth ? (
            <div class="uk-margin">
              <div class="uk-search uk-search-default">
                <input
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      executeSearch({ searchText });
                      if (selectedMarker) {
                        deselectMarker(selectedMarker);
                      }
                      openSearch();
                    }
                  }}
                  onChange={(event) => setSearchText(event.target.value)}
                  class="uk-search-input"
                  type="search"
                  placeholder="Search"
                  value={searchText}
                />
              </div>
            </div>
          ) : null}
          {isAuth && searchIsOpen ? (
            <li>
              <a
                onClick={() => {
                  closeSearch();
                  setSearchText("");
                }}
                className="uk-active"
                uk-icon="icon: close; ratio: 2"
              ></a>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="uk-navbar-right uk-margin-large-right uk-margin-top">
        <ul className="uk-navbar-nav">
          {isAuth ? <p>{username}</p> : null}
          {isAuth ? (
            <a className="uk-margin-left" onClick={logout}>
              LogOut
            </a>
          ) : null}
        </ul>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
  isAuth: isAuthenticated(state.auth),
  token: state.auth.token,
  searchIsOpen: state.search.open,
  selectedMarker: state.map.selected,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logOut()),
  openSearch: () => openSearch(dispatch),
  closeSearch: () => closeSearch(dispatch),
  deselectMarker: (marker) => deselect(dispatch)({ marker }),
  searchMarkers:
    ({ token }) =>
    ({ searchText }) =>
      searchMarkers({ token, searchText })(dispatch),
  reloadMarkers:
    ({ token }) =>
    () =>
      dispatch(getMarkers({ token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
