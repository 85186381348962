import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/protectedRoute.jsx'
import Pages from './pages'
import TopBar from './components/TopBar.jsx'
import Alert from './components/Alert.jsx'

export default ({ store }) => (
  <Provider store={store} >
    <BrowserRouter>
      <Alert />
      <TopBar />
      <Switch>
        <Route path="/login" exact component={Pages.LoginPage} />
        <ProtectedRoute path="/" exact component={Pages.DashboardPage}></ProtectedRoute>
        <ProtectedRoute path="/info/:id" component={Pages.DetailPage}></ProtectedRoute>
        <ProtectedRoute path="/fattura/:dtv/:id_fattura" component={Pages.FatturaDetailPage}></ProtectedRoute>
        <ProtectedRoute path="/deposito/:idDocumento" component={Pages.DepositoDetailPage}></ProtectedRoute>
        <ProtectedRoute path="/nota/:idRecord" component={Pages.EventoDetailPage}></ProtectedRoute>
        <ProtectedRoute path="/new/note/:idAnagrafica" component={Pages.NewEvento}></ProtectedRoute>
        <Route component={Pages.LoginPage} />
      </Switch>
    </BrowserRouter>
  </Provider>
)