import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFatturaDetail } from '../fattura/useCases'
import { FullScreenSpinner } from '../components/map/mapContainer.jsx'
import { FatturaDetail } from '../components/FatturaDetail.jsx';
import { Link } from 'react-router-dom'

class FatturaInfoPage extends Component {

  componentDidMount() {
    const { match, fetchFatturaDetails, token } = this.props
    const dtv = match.params.dtv
    fetchFatturaDetails({ dtv, token })
  }

  render() {
    const { isLoading, fattura, error, match, history } = this.props
    return (<div className="uk-container uk-margin-top" >
      {isLoading || !fattura ?
        FullScreenSpinner():
        error ?
          <div>{error.message}</div> :
          <div>
            <button onClick={() => history.goBack()} className="uk-button uk-button-primary uk-margin-bottom">Indietro</button>
            <p className="uk-text-large uk-text-center" >ID: {match.params.id_fattura}</p>
            <hr className="uk-divider-icon uk-margin-bottom-large"></hr>
            <FatturaDetail fattura={fattura} />
            <button onClick={() => history.goBack()} className="uk-button uk-button-primary uk-margin-bottom">Indietro</button>
          </div>
      }
    </div>)
  }

}

const mapStateToProps = state => ({
  token: state.auth.token,
  isLoading: state.fattura.loading,
  fattura: state.fattura.fattura,
  error: state.fattura.error
})

const mapDispatchToProps = dispatch => ({
  fetchFatturaDetails: ({ dtv, token }) => dispatch(getFatturaDetail({ token, dtv }))
})

export default connect(mapStateToProps, mapDispatchToProps)(FatturaInfoPage)