import { makeEvent } from '../../../common/entityes/index'

export const FETCH_EVENTO = 'FETCH_EVENTO'
export const ERROR_FETCH_EVENTO = 'ERROR_FETCH_EVENTO'
export const EVENTO_FETCHED = 'EVENTO_FETCHED'
export const CREATE_EVENTO = 'CREATE_EVENTO'
export const EVENTO_CREATED = 'EVENTO_CREATED'
export const EVENTO_CREATION_ERROR = 'EVENTO_CREATION_ERROR'
export const CLOSE_ENVENT_SUCCESS_ALERT = 'CLOSE_ENVENT_SUCCESS_ALERT'

export const defaultState = {
  evento: null,
  loading: false,
  error: null,
  newEvent: null,
}

export const makeFetchEventoAction = () => ({
  type: FETCH_EVENTO
})

export const makeEventoFetchedAction = ({ evento }) => ({
  type: EVENTO_FETCHED,
  data: evento
})

export const makeFetchEventoErrorAction = ({ error }) => ({
  type: ERROR_FETCH_EVENTO,
  data: error
})

export const makeCreateEvent = ({ evento }) => ({
  type: CREATE_EVENTO,
  data: makeEvent(evento).getInfo()
})

export const makeErroredEventCreation = ({ error }) => ({
  type: EVENTO_CREATION_ERROR,
  data: error
})

export const makeEventCreated = () => ({
  type: EVENTO_CREATED
})

export const closeSuccessPopup = () => ({
  type: CLOSE_ENVENT_SUCCESS_ALERT
})

export default (currentState = defaultState, action) => {
  switch (action.type) {
    case FETCH_EVENTO:
      return {
        ...currentState,
        loading: true
      }
    case ERROR_FETCH_EVENTO:
      return {
        ...currentState,
        loading: false,
        error: action.data
      }
    case EVENTO_FETCHED:
      return {
        ...currentState,
        evento: action.data,
        loading: false,
        error: null
      }
    case CREATE_EVENTO:
      return {
        ...currentState,
        newEvent: action.data,
        loading: true
      }
    case CLOSE_ENVENT_SUCCESS_ALERT:
      return {
        ...currentState,
        newEvent: null
      }
    case EVENTO_CREATED:
      return {
        ...currentState,
        loading: false
      }
    case EVENTO_CREATION_ERROR:
      return {
        ...currentState,
        loading: false,
        error: action.data,
        newEvent: null
      }
    default:
      return currentState
  }
}