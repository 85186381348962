import "react-app-polyfill/ie9";
import React from "react";
import { render } from "react-dom";
import App from "./react-app/index.jsx";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers, { loadState, saveState } from "./react-app/reducer";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "./style/main.scss";

UIkit.use(Icons);

window.NODE_ENV = __NODE_ENV__ || "development";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadedState = loadState();
const store = createStore(
  reducers,
  loadedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
});

const root = document.getElementById("root");
render(<App store={store} />, root);
