import {
  makeFetchInfoAction,
  makeInfoFetchErrorAction,
  makeInfoHasBeenFetchedAction,
  makeCacheInfoAction
} from './reducer'
import { makeHttpApiClient } from '../http/index'

export const fetchInfoBy = ({ token, cachedInfo }) => ({ idAnagrafica }) => {
  const api = makeHttpApiClient(token)
  return dispatch => {
    if (cachedInfo[idAnagrafica]) {
      dispatch(makeInfoHasBeenFetchedAction({ info: cachedInfo[idAnagrafica] }))
      return new Promise((a) => a())
    }
    dispatch(makeFetchInfoAction())
    return api({ path: `/info/${idAnagrafica}` })
      .then(info => {
        dispatch(makeCacheInfoAction({ idAnagrafica, info }))
        dispatch(makeInfoHasBeenFetchedAction({ info }))
      })
      .catch(error => dispatch(makeInfoFetchErrorAction({ error: error.error_data.error })))
  }
}

